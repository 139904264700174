import React,{Component} from 'react'
import axios from "axios"
import { Navigate } from 'react-router';
class Login extends Component {
  
  state = {
    redirect: false,
   
  }

  

  constructor(props) {
      super(props);
      this.state = {
        username: '',
        password: '',
        error: '',
        loading: false
      };
      this.state = {username: "", password: ""};
      
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      
  }


  handleChange(event) {
      this.setState({[event.target.name]: event.target.value}
       
        );
  }
 
 
 

    
  async handleSubmit(event) {
      event.preventDefault();
       
      try {
         
          const response = await axios.post('https://api.sms.et/commons/login/',
            {
              username:this.state.username,
              password:this.state.password
            }
          )

          
           
          this.setState({ redirect: true })
          localStorage.setItem('access_token',response.data.token)
          return response;
      } catch (error){
        this.setState({error: "Couldn't login with this information,check your password and username try again ", loading: false});
      }
     
      
  } 
  
   render () {
    const { redirect } = this.state;

   if (redirect) {
     return <Navigate to="/home"/>;
      
   }

   

  
    return (
      <div>
         <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 px-6 lg:px-8">
<div className="sm:mx-auto sm:w-full sm:max-w-md">
  
{this.error && <small className="text-red-600  m-2 font-bold text-xl">Something went wrong. Please try again later.</small>}
  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login .</h2>
  
</div>

<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
  <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
    <form  onSubmit={this.handleSubmit}  className="mb-0 space-y-6" action="#" method="POST">
      <div className="text-gray-400 focus-within:text-gray-600 ...">
        <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">User Name</label>
       
        <div className="mt-1">
          <input id="username" name="username" type="username" autoComplete="username" required className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded-sm py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          value={this.state.username} 
    
          onChange={this.handleChange}
     
           
          />
        </div>

      </div>

      <div>
        <label htmlFor="password" className="bllock text-black font-bold md:text-right mb-1 md:mb-0 pr-4">Password</label>
        <div className="mt-1">
        <input className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="******************" 
          value={this.state.password} 
          name = "password"
        
          onChange={this.handleChange}

    
        />
        <div className="text-red-500 text-bold">
{  this.state.error}
</div>
        </div>
      </div>

   <button     className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Login</button>

      <div>
       
      </div>
    </form>
    
  </div>
</div>

</div>
</div>
      

    );
}
}
export default Login;
