import React from 'react';

const Pagination = ({ totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / 100); i++) {
      pageNumbers.push(i)  
  }

  return (
    <nav className='border p-2'>
      <ul className='pagination flex space-x-2 px-2 py-3'>

       
       
        {pageNumbers.map(number => (
         
          <li key={number} className='page-item bg-transparent  rounded-sm bg-yellow-200  text-black border border-gray-300 px-2'>
            <button onClick={() => paginate(number)}  className='page-link '>
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
