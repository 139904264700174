
import Login from './components/Login/Login';
import React from 'react';
import Landingpage from './components/Landingpage/Landingpage';
import Price from './components/content/Price';
import Contact from './components/content/Contact';
import Dashboard from './components/Dashboard/Dashboard';
import Aboutus from './components/content/Aboutus';
import {BrowserRouter ,Routes,Route } from 'react-router-dom'
import ProtectedRoute from './components/Dashboard/Protectedroute';
import Notfound from './components/Notfound/Notfound';
import WithNav from './components/Navbar/with';
import Without from './components/Navbar/without';
import Sendsms from './components/Dashboard/Sendsms';
import Bulksms from './components/Dashboard/Bulksms';
import WithNavLogin from './components/Navbar/WithNavLogin';
function App() {
  return (
    // <Login />
    <BrowserRouter>
     
     
       <Routes>
        <Route element = {<WithNav/>}>
        <Route exact path="/" element={<Landingpage/>} /> 
        <Route exact path="/about" element={<Aboutus/>} />
        <Route exact path="/content" element={<content/>} />
         <Route exact path="/price" element={<Price/>} />
         
         <Route exact path="/contact" element={<Contact/>} />
         <Route exact path="/login" element={<Login/>} />
         
         
        <Route  path="*" element={  <Notfound/> }    />
        </Route>
          <Route element ={<Without/>}>
         
     
        

         </Route> 
        
         <Route element ={<WithNavLogin/>}>
         <Route exact path='/bulk-send-sms' element={<ProtectedRoute>
              <Bulksms/>
            </ProtectedRoute>
           } />
        
         <Route exact path="/send-sms" element={<ProtectedRoute>
          <Sendsms />
       
            </ProtectedRoute>
           }/>

         <Route exact path='/home' element={
             <ProtectedRoute>
              <Dashboard/>
            </ProtectedRoute>
           } />

         
   
         
         </Route>
               
        

   
       </Routes>

    </BrowserRouter>
 
  );
}

export default App;
