import React from 'react'

import 'react-slideshow-image/dist/styles.css'

function Wms() {
    return (
        
        <div>

        <div className="justify-center ">
                <h1 className=" p-10 text-3xl text-center tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
               
                <span className=" block text-gray-700 xl:inline   ">What member are saying...</span>
                </h1>
               
                 </div>
           

           
        
            <div className= "flex flex-col lg:flex-row md:flex-row -space-y-2">

            <div className=" p-2 relative  transition transform hover:-translate-y-1 hover:scale-110 w-full " >
              {/* yello background */}
              {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
            <div className="relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
            <div className="bg-yellow-400 h-3 w-10">  </div>
            {/* yellow line  */}
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                Yenebilh Bantahun
                </div>
                <div className="text-3xl font-extrabold">
                {/* the price content  */}
                Vamos Enternainment


                </div>
                {/* description */}
               <div className='font-thin text-3xl'>
                Impeccable service
              
                {/* the get started */}
                </div>
            </div>
          
          </div>


              


          <div className=" p-2 relative  transition transform hover:-translate-y-1 hover:scale-110 w-full " >
              {/* yello background */}
              {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
            <div className="relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
            <div className="bg-yellow-400 h-3 w-10">  </div>
            {/* yellow line  */}
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                 Misikr Hassen
                </div>
                <div className="text-3xl font-extrabold">
                {/* the price content  */}
                Habesha Sport Betting

                </div>


                <div className='font-thin text-3xl'>
                Umatched service with cheap price
              
                {/* the get started */}
                </div>

            </div>
            
          </div>

          <div className=" p-2 transition transform hover:-translate-y-1 hover:scale-110 w-full " >
              {/* yello background */}
              {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
            <div className=" bg-gray-800 text-gray-50 h-39 rounded-xl p-8 space-y-5">
            <div className="bg-yellow-400 h-3 w-10">  </div>
            {/* yellow line  */}
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                Temesgen Gebru
                </div>
                <div className="text-3xl font-extrabold">
                {/* the price content  */}
              Arada Entertainment

                </div>
                {/* description */}
             
                <div className='font-thin text-3xl'>
                Loved the service
              
                {/* the get started */}
                </div>

            </div>
            
          </div>
          </div>
  
        </div>
    )
}

export default Wms
