import React from 'react'

function Contact() {
    return (
   
    
        <div>
      
      <div className="justify-center ">
                <h1 className=" p-10 text-4xl text-center tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
               
                <span className=" block text-gray-700 xl:inline   ">Contact</span>
                </h1>
               
                 </div> 
           <div className =" flex flex-col md:flex-row lg:flex-row">
      {/* the card goes here first the card top header  */}
      <div className="p-2 relative   transition transform hover:-translate-y-1 hover:scale-110 w-full  " >
          {/* yello background */}
          {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
        <div className="relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
        <div className="">     
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 stroke-current text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
           </div>
        {/* yellow line  */}
            <div className="text-4xl font-extrabold">
            {/* the price content  */}
             Phone
          
            </div>

            <div className="font-semibold">
            {/* the price content  */}
            <ul className="list-disc">
              <li>
            +251-911-98-53-65
            </li>
            <li>
            +251-977-20-77-77
            </li>
            </ul>
            </div>
            {/* description */}
            {/* when a user creates a new account with the company, the companies use our platform to send activation code directly to the user to confirm that the user actually is real and that the phone number registered with is his own. */}
            {/* the get started */}

        </div>
      </div>

      <div className="p-2  relative   transition transform hover:-translate-y-1 hover:scale-110 w-full " >
          {/* yello background */}
          {/* <div class="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
        <div className="relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
        <div >
           <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 stroke-current text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                </svg>
              </div>
        {/* yellow line  */}
            <div className="text-4xl font-extrabold">
            {/* the price content  */}
            Email 
            </div>
            <div className="font-semibold">
            {/* the price content  */}
            <ul  className="list-disc">
              <li>
            negassab16@gmail.com </li>
            <li>
            negassa.berhanu@kekros-ethiopia.com
            </li>
            </ul>
            </div>
            <div className="text-4xl font-extrabold">
            {/* the price content  */}
               
            </div>
            {/* description */}
                
            {/* the get started */}

        </div>
      </div>

      <div className="p-2 relative   transition transform hover:-translate-y-1 hover:scale-110 w-full " >
          {/* yello background */}
          {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
        <div className="relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
        <div >   <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 stroke-current text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
    </div>
        {/* yellow line  */}
            <div className="text-4xl font-extrabold">
            {/* the price content  */}
            location
            </div>
            <div className=" font-semibold">
            {/* the price content  */}
             Bole subcity, Woreda 03, Dasset Building, 2351/22 house number , Addis Ababa, Ethiopia
            </div>
            {/* description */}
                
            {/* the get started */}

        </div>
      </div>


      </div>
 
        </div>
    )
}

export default Contact
