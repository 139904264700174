import React from 'react'

import img from '../../assets/hugo-95.png';
function Aboutus() {
    return (
        <div>
              <div className='p-20'> 
                <div className="justify-center ">
               
               
                 </div>
            </div>
           
            <div className="flex flex-col-reverse lg:flex-row-reverse md:flex-col-reverse justify-between  ">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <h1 className=" text-4xl p-2 text-left tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
               
             About Us
               </h1>


        
           
         <div className='grid grid-rows-1 gap-2'>
         <p className="font-thin py-4 p-2">
              Kekros Technologies is a company registered in the Federal Democratic Republic of Ethiopia (FDRE) engaged in several businesses
               primary of which are Value Added Telecommunication Services (VAS) and Internet of Things (IoT). In the short time it has been in 
               business it has achieved remarkable progress in all of its endeavours and continues to do so. More information can found on our website www.kekros-ethiopia.com
              </p>
              <div className='p-2'>
              <a
                    href="https://kekros-ethiopia.com/  " target="_blank" rel="noreferrer"
                    className="flex items-center py-5 lg:w-52 md:w-52 w-full justify-center p-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 "
                  >
                    Vist Kekros
                  </a>
                  </div>
        </div>
         
        </div>
     
        
    
    
    <div className="  flex md:justify-center justify-center lg:justify-end">
            
            

            <img
              className="md:w-auto   lg:w-screen  lg:h-full  w-auto p-2"
              src={img}
              alt=""
            />
    
 

            
         
        </div>

        
        
     
    </div>
      </div>

      
         
        
    )
}

export default Aboutus
