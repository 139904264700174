
function Notfound(){

    return (
        <div>

            <div className = "flex justify-center py-52">
            <h1 className="text-7xl font-bold text-gray-500">
                404 not found
            </h1>
            </div>
        </div>
    );
}


export default Notfound;