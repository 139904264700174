import React from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'
function Sendsms() {

const [isLoading, setIsLoading] = React.useState(false);
const [sms_number_to, setSmsNumberTo] = React.useState("");

const [empty, setEmpty] = React.useState(false)
const [ setSuccess] = React.useState(false)


const[sms_content, setSmsContent] = React.useState("");


// function leftFillNum(num, targetLength) {
//   return num.toString().padStart(targetLength, 0);
// }

React.useEffect(() => {
    if(!sms_content === "" && !sms_number_to === ""){
    setEmpty(false);
  }
  },[sms_number_to,sms_content]);

 

function sendSms() {
   if(sms_content === "" || sms_number_to === ""){
    setEmpty(true);
    return;

  }
    setEmpty(false);
    setIsLoading(true);
    const payload = JSON.stringify({
          sms_number_to: sms_number_to,
          sms_content: sms_content
        });
    var config = {
  method: 'post',
  url: 'https://api.sms.et/notification/sendsms/',
  headers: { 
    'Authorization': `token ${localStorage.getItem('access_token')}`, 
    'Content-Type': 'application/json'
  },
  data : payload
};
    axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  setIsLoading(false);
  setSuccess(true )
})
.catch(function (error) {
  console.log(error);
  setIsLoading(false);
});
  }


  return (
    <div className='bg-slate-50 w-screen h-screen' >
        
      
    <div className='py-4  justify-center flex px-20  '>

          <div>

{ 
empty ?

<div  className="mb-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative " role="alert">
  
  {sms_number_to === "" ? <div><strong className="font-bold">!</strong><span className="block sm:inline"> Phone number can not be empty</span></div>: null}
  {sms_content === "" ? <div><strong className="font-bold">!</strong><span className="block sm:inline"> Message can not be empty</span> </div>: null}
  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
   
     <svg type="button"  onClick={()=>setEmpty(false)} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
  </span>
</div> 
: <br/>
} 

 <div className='grid grid-cols-1'>
     <Link to ='/home'>
        <button

                    className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                   
                  >
                    back
                  </button>
              </Link>
      </div>

        <div className='border p-20  rounded-md bg-white'>
            <form >
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone number</label>
            <div className='flex space-x-2'>
                
           <div>     
            
 
             </div>
       
       <div className="mt-1">
         <input     type="text"  className=" form-control appearance-none rounded-md block w-full bg-gray-100 text-gray-700 border border-gray-200  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={sms_number_to} 
            
            onChange={e => setSmsNumberTo(e.target.value)} />
      
             </div>
             
             </div>
             <label className="form-label  font-bold text-gray-700 ">Message</label>
             <div className="form-outline mt-2">
                 <textarea
                    
                    
                  className="form-control  form-control appearance-none rounded-md block w-full bg-gray-100 text-gray-700 border border-gray-200  py-20 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                  value={sms_content}
                  onChange={e => setSmsContent(e.target.value)}
                  ></textarea>
 
             </div>
             
            </form>
            <div className='flex justify-end mt-3'>
            <button
                    className={`${isLoading ? "cursor-not-allowed" : ""} text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
                    onClick={()=>sendSms()}
                   disabled={isLoading ? "true" : ""}
                  >
                      
                    {isLoading ? "Sending..." : "Send"}
                  </button>
            </div>
        </div> 
          </div>

       </div>
    </div>
  )
}

export default Sendsms