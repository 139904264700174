import React from 'react'
import {Link, useNavigate } from "react-router-dom";
import ricapic from '../../assets/rica-.png';
function Navafter() {
    const [showmodal, setShowModal]= React.useState(false)
    const navigate = useNavigate();
    function backtologin(){
        localStorage.removeItem("access_token");
        localStorage.removeItem("refersh_token");
        navigate("/login")
         
      }

      
  return (
      
    <div>
         <nav className="font-sans flex space-y-5 flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 w-full  bg-white shadow sm:items-baseline ">
           
           <div className=" flex justify-center">
             <a href="/home" className="text-2xl no-underline text-grey-darkest hover:text-blue-dark font-bold  px-4">
 <div className="p-5 font-bold text-2xl md:text-3xl lg:text-3xl ">
                      
                      <div className="font-extrabold ">sms<span className="text-yellow-500">.et</span> </div>              
                        
                     </div>

             </a>
               </div>
                <div className=''>
                 <button onClick={() =>  setShowModal(true)} type="button"  className="w-full -m-2 flex justify-center py-4 px-9 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 duration-200">
                  Logout
                 </button>
                 {
                   showmodal ?(
                     <>
                     <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
           <div className="relative w-auto my-6 mx-auto max-w-3xl">
             <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
               <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                 <h3 className="text-3xl font-thin">Are you sure you want to logout  </h3>
   
               </div>
             
               <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                 <button
                   className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                   type="button"
                   onClick={() => setShowModal(false)}
                 >
                   Close
                 </button>
                 <button
                   className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                   type="button"
                   onClick={()=>backtologin()}
                 >
                   yes
                 </button>
               </div>
             </div>
           </div>
         </div>
                     </>
                   ):null
                 }
               </div>
         </nav>  
    </div>
  )
}

export default Navafter