
import { React} from 'react'
import Contact from '../content/Contact'
import Price from '../content/Price'
import Aboutus from '../content/Aboutus';
import Content from '../content/content';
import Wms from './Wms';
import Footer from '../content/Footer';
import message from '../../assets/message.png'
import {Link} from 'react-router-dom';
function Landingpage() {
  
    return (
        <div>
           
               
               <div className="relative bg-white overflow-hidden">
                <div className="flex flex-col-reverse lg:flex-row md:flex-col-reverse justify-between">
                  <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                    


          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-10 xl:mt-28">
            <div className="sm:text-center lg:text-left md:text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Service  </span>{' '}
                <span className="block text-yellow-500 xl:inline">offering</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg font-thin sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:text-left md:text-center">
              We provide a platform that delivers Short Message Service (SMS) characterized as Mobile 
              Terminated (MT) to different sports betting companies that utilize the platform for different 
              use cases. The following describes the most common use-cases for the platform:
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start md:justify-center">
                <div className="rounded-md shadow">
                 <Link to='/login'>
                  <button
                  
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </button>
                  </Link>
                </div>
              
              </div>
            </div>
          </main>
          
         
        </div>
       

        <div className=" flex md:justify-center justify-center lg:justify-end">
            
   

       
  
        <img
        className="     md:w-auto     lg:w-screen  lg:h-full   w-auto   "
      
        src={message}
        alt=""
      />

       

        
     
    </div>

      </div>
      
    </div>
    <div>

    </div>
    <Wms/>
     <Aboutus/>
    
    <Content/>
    
  
    
    <Contact/>
    <Footer/>


</div>
    )
}

export default Landingpage
