import React from 'react'
import Navafter from './Navafter';
import { Outlet } from 'react-router';
function WithNavLogin() {
  return (
      <>
    < Navafter/>
    <Outlet />
    </>
  )
}

export default WithNavLogin