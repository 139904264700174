import React from 'react'

function content() {
    return (
        
      <div>
 
            <div className="justify-center  ">
                <h1 className=" p-10 text-4xl text-center tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
               
                <span className="  text-gray-800 xl:inline ">Services</span>
                </h1>
               
            </div> 
  <div className='grid lg:grid-cols-2 md:grid-cols-2  grid-rows-1 justify-items-center gap-y-6 '>

<div className='p-2 w-screen lg:w-60 md:w-60'>
    <div className='p-2   bg-gray-800 text-white rounded-md   '>
 
      <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
   <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
 </svg>
       
   <div className='p-2 text-2xl font-bold'>  Sending Announcements</div>
    </div>

    </div>
    <div className='w-screen p-2 lg:w-60 md:w-60'>
        <div className=' bg-gray-800 text-white p-3 rounded-md   '>
        
        <p className=" text-left font-thin ">
      The companies use our platform to directly reach thier customer 
     base for a relatively cheap price and inform them of their new offerings.</p>
     
        </div>
    </div>


    <div className='w-screen  lg:w-60 md:w-60'>
    <div className='p-2  bg-gray-800 text-white rounded-md    '>
      
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z" clipRule="evenodd" />
            <path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z" />
          </svg>
   
        <div className="p-2 text-white text-2xl font-bold   ">Sending activation code</div>
        
    </div>
    </div>

    <div className='w-screen p-2 lg:w-60 md:w-60'>
      <div className=' bg-gray-800 text-white p-3 rounded-md   ' >
    <p className=" text-left font-thin  ">
    When a user creates a new account with the company,
      the companies use our platform to send activation 
      code directly to the user to confirm that the user actually is 
      real and that the phone number registered with is his own.
      </p>
      </div>
    </div>

   
    <div className='w-screen p-2 lg:w-60 md:w-60'>
    <div className='p-2  bg-gray-800 text-white rounded-md   '>
        <>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
        </svg>
        </>
        <div className="text-white text-2xl font-bold  p-2 ">Sending reset password</div>
        
    </div>
    </div>

    <div className='w-screen p-2 lg:w-60 md:w-60'>
      <div className=' bg-gray-800 text-white p-3 rounded-md  ' >
    <p className=" text-left font-thin  ">
        Whenever a user forgets his password to his account, 
     the companes use our platform to send the new password
      directly to the user thus giving access to the account securely.
      </p>
      </div>
    </div>

    <div className='w-screen p-2 lg:w-60 md:w-60'>
    <div className='p-2  bg-gray-800 text-white rounded-md  '>
        <>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
        </svg>
        </>
        <div className="text-white text-2xl font-bold  p-2 ">Sending withrawal code</div>
        
    </div>
    </div>

    <div className='w-screen p-2 lg:w-60 md:w-60'>
      <div className='shadow-lg bg-gray-800 text-black p-2 rounded-md  ' >
    <p className=" text-left text-white font-thin ">
      When a user gets lucky and wins, it's the utmost necessity to make sure that 
     the actual winner gets to collect the money, this is where the platform comes in. 
     The companies use our platform to send the requested withdrawal code directly to 
     the user thus making sure that only the actual winner collects the money
      </p>
      </div>
    </div>

  </div>
    
    
     


     </div>
    )
}

export default content
