import React from 'react'


export default function Price() {
    return (
        <div >
            <div > 
                <div className="justify-center ">
                <h1 className=" p-2 top-10 bottom-20 text-4xl text-center tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl">
               
                <span className="p-10 block xl:inline">Monthly Prices</span>
                </h1>
                 </div>
            </div>
      <div className = "grid  lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 space-y-2 m-2 lg:space-x-2 md:space-x-2 ">
          {/* the card goes here first the card top header  */}
          <div>
          <div className=" relative   transition transform hover:-translate-y-1 hover:scale-110  p-2 " >
              {/* yello background */}
              {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400 h-full w-full"></div> */}
            <div className="relative bg-gray-800 text-gray-50 rounded-md p-8 space-y-5">
            <div className="bg-yellow-400 h-3 w-10">  </div>
            {/* yellow line  */}

            <div className ="text-white font-bold">vat excluisve</div>
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                    Basic
                </div>
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                    90 Birr
                </div>
                {/* description */}
                     <ul className="list-disc p-4">
                     <li className="">SMS message</li>
                    
                    </ul>
                {/* the get started */}

            </div>
          </div>
          </div>
         <div> 
          <div className="p-2 relative   transition transform hover:-translate-y-1 hover:scale-110  " >
              {/* yello background */}
              {/* <div className="absolute rounded-xl left-10   bottom-8 bg-yellow-400  h-full w-full"></div> */}
            <div className="grid grid-cols-1 relative bg-gray-800 text-gray-50 rounded-xl p-8 space-y-5">
          
                    <div className="bg-yellow-400 h-3 w-10"></div>
                    <div className ="text-white font-bold">vat excluisve</div>
                  
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                    Pro
                </div>
                <div className="text-4xl font-extrabold">
                {/* the price content  */}
                    90 Birr
                </div>

                {/* description */}
                     <ul className="list-disc p-4">
                    <li className="">Telegram bot</li>
                    <li className="">SMS message</li>
                   
                    
                    </ul>
                {/* the get started */}

               
            </div>
          </div>
          </div>

    

          </div>
        </div>
    )
}
 