import React,{useState, useEffect} from 'react'
import {Link, useNavigate } from "react-router-dom";
import axios from'axios';


import Paginations from './Pagination';




function Dashboard() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading]= useState(false);
    const [error,setError] = useState(false)
    const [offset, setoffset] = useState(1);
    const [totalPosts,setTotalpost] = useState([])
    const [count, setCount] = useState()


   



 
 

    function getTotal(){

      axios.get('https://api.sms.et/notification/smscount/',{
        headers: {
          'Content-Type': 'application/json',
         Authorization: `Token  ${localStorage.getItem('access_token')}`
        
    }
      },{
        
  
  })
       .then((res)=>{
        setTotalpost(res.data);
        setLoading(false)

        console.log(res.data)
       })
       .catch(setError(true))
  }



  useEffect(()=>{
    getTotal()
 },[] )
 

  function getPost(){

    axios.get('https://api.sms.et/notification/viewsms/?page=' + offset,{
   headers: {
        'Content-Type': 'application/json',
       Authorization: `Token  ${localStorage.getItem('access_token')}`
      
  }
})
     .then((res)=>{
      setPosts(res.data.results);
      setCount(res.data.count)
  
      setLoading(false)
      
      console.log(res.data)
     })
     .catch(setError(true))

    }
  
useEffect(()=>{
  
getPost()
  
},[offset] );


const paginate = (pageNum)=>setoffset(pageNum);



    return (
      
        <div className="">
          {
            loading && <div><svg role="status" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg></div>
          }
     
    <div> 

  <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 items-center gap-4 p-3 '>

  <div className='bg-green-300 font-bold grid-cols-1 rounded-md'>
    <div className='p-3 text-2xl text-white'>
      Sent
    </div>
    <div className='p-3 text-lg text-white'>
     { totalPosts.delivered}
    </div>

 </div>

   <div className='bg-red-300 font-bold grid-cols-1 rounded-md'>
  <div className='p-3 text-2xl text-white'>
      Failed
    </div>
    <div className='p-3 text-lg text-white'>
    {totalPosts.failed}
    </div>

  </div>
  <div className='bg-yellow-300 font-bold grid-cols-1 rounded-md'>
  <div className='p-3 text-2xl text-white'>
      Total this month
    </div>

    <div className='p-3 text-lg text-white'>
    {totalPosts.total}
    </div>
  </div>
</div>

<div className="flex flex-col p-2">
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className='flex'>
          <div className=' px-2 '>
              <Link to="/send-sms">
                <button

                        className="p-0 w-14 h-14 bg-yellow-500 rounded-full hover:bg-yellow-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
                  <div className='flex justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"  strokeWidth="1" stroke="currentColor"  viewBox="0 0 20 20" fill="currentColor">
                  <path fill="#FFFFFF"  d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                </svg>
                </div>
                </button>
              </Link>
              
              </div>
              <div className=' px-2 '>
              <Link to='/bulk-send-sms'>
                <button
                        className="p-0 w-14 h-14 bg-yellow-500 rounded-full hover:bg-yellow-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
                <div className='flex justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"  strokeWidth="1">
          <path fill="#FFFFFF"  strokeLinejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>
        </div>
                </button>
                </Link>
              </div>
              
              </div>
            <div className="overflow-hidden shadow-md sm:rounded-md">

                <table className="min-w-full">
                    <thead className="bg-gray-50 ">
                 <tr>
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            SMS Number
            </th>
             
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
            sms content
            </th>
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            sent date
            </th>
            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            sent status
            </th>
           
            </tr>
            </thead>
   


    {
    posts.map((post)=>

    
      
          <tbody className="">
        <tr className="bg-white border-b  ">
            <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap ">
            {post.sms_number_to}
         </td>

           <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
           {post.sms_content}
          </td>

          <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
          {post.sent_date}
           </td>
           <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
           {post.delivery_status ? 'sent':'failed'}
           </td>
        </tr>
            
            </tbody>
    )
}



     {/* /{err && <small className="text-red-600  m-2 font-bold text-xl">Something went wrong. Please try again later.</small>} */}
        
              </table>
             
           <div className="flex w-52 overflow-x-auto"> 
                

              <Paginations  totalPosts={count} paginate={paginate}/>
          </div>
              <div className="flex items-center justify-around">
    
          </div>
            </div>
         </div>
            </div>
        </div>

          </div>
        
        
                      
                  </div>
          )
}

export default Dashboard







