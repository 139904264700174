import React from 'react'

function Footer() {
    return (
        <div>
            <div className="bg-gray-800 w-full h-28">

            </div>
        </div>
    )
}

export default Footer
