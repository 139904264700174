import React from 'react'
import { Outlet } from 'react-router';

import {Link} from 'react-router-dom';
function Navbar() {
    
  
    return (
        <div>
             <nav className= "bg-gray-50 sticky  top-0 z-50">
                
                <div className= " auto border ">
                <div className= "flex justify-between">
                <div className="p-5 font-bold text-2xl md:text-3xl lg:text-3xl ">
                 <Link to="/"> 
                      <div className="font-extrabold ">sms<span className="text-yellow-500">.et</span> </div>              
                      </Link> 
                     </div>

                        <div className="flex space-x-5 p-5 ">
                       
                       
                      <Link to="/login">       
                      <button className="transition transform hover:-translate-y-1 hover:scale-110 p-5 bg-yellow-500 hover:bg-yellow-600 focus:outline-none   rounded-md px-8 py-3 text-white font-semibold">
                                   Login 
                                </button> 
                                </Link>    
                      </div>
                    
                     
                    
   
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar ;

