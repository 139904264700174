import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

import Papa  from "papaparse";
let bulkData = [];

function Bulksms() {

 
  const [errorr, setError] = useState(false)
  
  const [empty,setEmpty] = useState(false)
  const [message,setMessage] = useState("");

const [isLoading] = useState(false);

useEffect(() => {
    if(!message === ""){
    setEmpty(false);
  }
  },[message]);

   async function changeHandler(event){
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    await Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        bulkData = results && results.data;        
      },
    }); 

  };



function postReq(){
  setError(false);
   if(message === ""){
    setEmpty(true);
    return;
  }
  setEmpty(false);
  if(bulkData.length < 1){
    alert("CSV fail, can not be empty");
    return;
  }

  let data = [];
  for(var i = 0; i < bulkData.length; i++){
    data.push(bulkData[i][0]);
  }
 const payload = JSON.stringify({
          phone_nums: data,
          bulk_content: message
        });
  
   
    var config = {
        method: 'post',
        url: 'https://api.sms.et/notification/sendbulk/',
        headers: { 
          'Authorization': `token ${localStorage.getItem('access_token')}`, 
          'Content-Type': 'application/json'
        },
        data : payload
    };
    axios(config)
      .then(function (response) {
         
         alert("Bulk SMS has been sent succesfully");

      })
      .catch(function (error) {
          
          setError(true)
      });
}

 

  return (

 // if (message.length==0){
 //        setEmpty(true)
 //     }

    <div className='bg-slate-50 w-screen h-screen' >
        
      
    <div className='py-4  justify-center flex px-20   '>
    <div>
    <div className=''>
     <Link to ='/home'>
        <button

                    className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                   
                  >
                    back
                  </button>
              </Link>
      </div>



{ 
  errorr ?

<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
  <strong className="font-bold">!</strong>
  <span className="block sm:inline"> Something went wrong please try again.</span>
  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
    <svg onClick={()=>setError(false)} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
  </span>
</div> 
: null

}

{ 
empty ?

<div  className="mb-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative " role="alert">
  
  {message === "" ? <div><strong className="font-bold">!</strong><span className="block sm:inline"> Message can not be empty</span></div>: null}
  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
   
     <svg onClick={()=>setEmpty(false)} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
  </span>
</div> 
: null
} 

 


 


        <div className='border p-20  rounded-md  bg-white'>
           
           
            <div className='flex space-x-2'>
                
           
        
             
             </div>
             <div className="mt-1">
      
    
      
             </div>
             <label  className="block text-gray-700 text-sm font-bold mb-2">Drop your CSV</label>
       
             <div className="mt-1 bg-white">
         <input 
            type='file'
            accept='.csv'
            id = 'csvFile'
            
             onChange= {changeHandler}
            className=' bg-white '
    
          
         />
      
             </div>
             <form >
         
         
      
             <label className="form-label  font-bold text-gray-700 ">Message</label>
             <div className="form-outline mt-2">
                 <textarea
                  onChange={e => setMessage(e.target.value)}
                  className="form-control  form-control appearance-none rounded-md block w-full bg-gray-100 text-gray-700 border border-gray-200  py-20 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "></textarea>
 
             </div>
            </form>

            <div className='flex justify-end mt-3'>
                  
             <button
                    className={`${isLoading ? "cursor-not-allowed" : ""} text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
                    onClick={()=>postReq()}
                   
                   disabled={isLoading ? "true" : ""}
                  >
                      
                    {isLoading ? "Sending..." : "Send"}
                  </button>
            </div>
            
        </div> 
       </div>
    </div>
    </div>
  )
}
export default Bulksms
 