import React from 'react';
import Navbar from './Navbar';
import { Outlet } from 'react-router';

function withNav(){
    return(
        <>
        <Navbar />
        <Outlet />
      </>
    )
}
export default withNav;